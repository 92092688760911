import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 48,
    marginRight: theme.spacing(1),
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    letterSpacing: '0.02em',
  },
  atlasText: {
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    letterSpacing: '0.02em',
    paddingLeft: '0.15em',
  },
}));

const LogoIcon = ({ iconColor = '#98FF98', textColor = '#FFFFFF', atlasColor = '#98FF98', ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      {/* <PublicIcon className={classes.icon} style={{ color: iconColor }} /> */}
      <Typography variant="h5" component="span" className={classes.text} style={{ color: textColor }}>
        Event
      </Typography>
      <Typography
        component="span"
        variant="h5"
        className={classes.atlasText}
        style={{ color: atlasColor }}
      >
        Atlas
      </Typography>
      
    </Box>
  );
};

export default LogoIcon;