import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DoubleArrow } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import SimpleTabs from './SimpleTabs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStylesCard = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    flexDirection: 'horizontal',
    position: 'relative',
    // height: 450,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  customChip: {
    backgroundColor: 'blue',
    color: 'white'
  },
  subscribeButton: {
    position: 'relative',
    // top: '1%',
    // left: '27%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    visibility: 'visible',
  },
  subscribeButtonVisible: {
    visibility: 'visible',
  },
  saveButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {/* {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
  
const NewsCard = ({ id, pubdate, title, newsart, symbols, topcompanies, link, cardclass }) => {
  let {authTokens, serverurl, user, setPaymentmodal, setRegistermodal} = useContext(AuthContext)
  const classes = useStylesCard();

  const initialgraph = {};
  topcompanies.forEach((company) => {
    initialgraph[symbols[company]] = {"nodes":[],"links":[]};
    initialgraph["global"] = {"nodes":[],"links":[]};
  });

  const [open, setOpen] = useState(false);
  const [registeropen, setRegisteropen] = useState(false);
  const [graph, setGraph] = useState(initialgraph);
  const [cardsaved, setCardsaved] = useState(false);
  const [stockview, setStockview] = useState(1);
  const [isHovered, setIsHovered] = useState(false);

  const extractSymbol = (str) => {
    const parts = str.split(':');
    return parts.length > 1 ? parts[1] : str;
  };

  const handleViewtype = (event, newView) => {
    setStockview(newView);
  };

  // topcompanies = topcompanies.sort(() => Math.random() - 0.5);

  const truncateText = (text, maxLength) => {
    const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    return truncatedText;
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
    if (graph["global"]["nodes"].length === 0) {
      cardgraph();
    }
  };

  const authbearer = {'Authorization':'Bearer ' + String(authTokens.access)}

  const saveCard = async () => {
    if(user.is_registered) {
      const res = await fetch(serverurl+`/savecard/` + id,{headers:authbearer});
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      else {
        setCardsaved(true);
      }
    }
    else {
      setRegistermodal(true);
    }
  }

  const cardgraph = async () => {
    const res = await fetch(serverurl+`/graph/` + id+`/`,{headers:authbearer});
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    else {
      const aa = await res.json();
      setGraph(aa);
    }
  }
  
  return (
    <Card className={classes.root} onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>  
      <CardContent className={cardclass}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {pubdate}
        </Typography>
        <Typography variant="h5" component="h2" style={{ fontSize: 15, fontWeight: 'bold' }}>
          <Link href={link} target="_blank">
            {title}
          </Link>
        </Typography>
        <p style={{ lineHeight: 1.5 }}>           
          {truncateText(newsart,400)}
        </p>
      </CardContent>
      {!user.is_subscribed && cardclass != '' ? 
        <div className={`${classes.subscribeButton} ${isHovered ? classes.subscribeButtonVisible: ''}`}>
          <Button variant="contained" color="primary" onClick={() => setPaymentmodal(true)}>
            Subscribe
          </Button>
        </div> : ''}
        
        {topcompanies.length && (
        <div className={classes.saveButton}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={cardsaved}
            startIcon={<SaveIcon />}
            onClick={saveCard}
          >
            Save
          </Button>
        </div>
      )}
      
      <Dialog onClose={handleClose} aria-labelledby="max-width-dialog-title"
        open={open} fullWidth={true} maxWidth="xl">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h2" style={{ fontSize: 15, fontWeight: 'bold' }}>
                <Link href={link} target="_blank">
                  {title}
                </Link>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h7">
                {newsart}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </DialogTitle>
        <DialogContent dividers disableScrollLock={true} style={{height:"400px"}}>
          <SimpleTabs id={id} graph={graph} topcompanies={topcompanies} viewoption={stockview} symbols={symbols}/>
        </DialogContent>
        <DialogActions>
          <ToggleButtonGroup value={stockview} exclusive onChange={handleViewtype}>
            <ToggleButton value={1}>Impact <DoubleArrow/></ToggleButton>
            <ToggleButton value={2}>Company Profile</ToggleButton>
          </ToggleButtonGroup>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default NewsCard
