import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from './App';
import ForgotPassword from './ForgotPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignUpError from './SignUpError';
import VerifyEmail from './VerifyEmail';
import { AuthProvider } from './context/AuthContext';
import './index.css';
import PrivateRoute from './utils/PrivateRoute';

function RedirectToExternalHome() {
    // This component will redirect to the external URL
    window.location.href = 'https://eventatlas.org/home';
    return null; // This component doesn't render anything
}

const Home = () => {
    return (
        <div>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<PrivateRoute />} exact>
                            <Route exact path='/' element={<App />}/>
                        </Route>
                        <Route path="/home" element={<RedirectToExternalHome />} />
                        <Route path="/login" element={<SignIn />}/>
                        <Route path="/register" element={<SignUp />}/>
                        <Route path="/registererror:formerrors" element={<SignUpError />}/>
                        <Route path="/verifyemail" element={<VerifyEmail />}/>
                        <Route path="/forgotpassword" element={<ForgotPassword />}/>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </div>
    )
}

export default Home;
