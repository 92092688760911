import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { CompanyProfile } from 'react-ts-tradingview-widgets';
import AuthContext from '../context/AuthContext';
import NewsImpact from './NewsImpact';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ id, graph, topcompanies, viewoption, symbols }) {
  let {newsfeed} = useContext(AuthContext)
  
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const feedexchange1 = {"cnbc":"NYSE:","cnn":"NYSE:","financialtimes":"NYSE:","bbc":"NYSE:",
"moneycontrol":"BSE:","businessstandard":"BSE:","economictimes":"BSE:","timesofindia":"BSE:","favorite":"BSE:"};
  const feedexchange2 = {"cnbc":"NYSE:","cnn":"NYSE:","financialtimes":"NYSE:","bbc":"NYSE:",
"moneycontrol":"NSE:","businessstandard":"NSE:","economictimes":"NSE:","timesofindia":"NSE:","favorite":"NSE:"};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let element;

  switch(viewoption) {
    case 1:  {
      element = topcompanies.map((company, i) => (
          <TabPanel key={i} value={value} index={i}>
            {/* <ImpactGraph graph={graph[symbols[company]]} /> */}
            <NewsImpact id={id} symbol={symbols[company]} />
          </TabPanel>
      ));
      break;
  }
  // case 2: {
  //   element = topcompanies.map((company,i) => (
  //     <TabPanel value={value} index={i}>
  //       <TradingViewWidget theme={Themes.DARK} height="270px" style="2" hide_top_toolbar={true} 
  //         symbol={symbols[company]} />
  //     </TabPanel>
  //   ))
  //   break
  // }
  // case 3: {
  //   element = topcompanies.map((company,i) => (
  //     <TabPanel value={value} index={i}>
  //       <FundamentalData symbol={symbols[company]} colorTheme="light" 
  //   isTransparent={true} locale="in" displayMode="adaptive" height={400} width="100%"/>
  //     </TabPanel>
  //   ))
  //   break
  // }
  // case 4: {
  //   element = topcompanies.map((company,i) => (
  //     <TabPanel value={value} index={i}>
  //       <TechnicalAnalysis symbol={symbols[company]} colorTheme="dark" 
  //       height={400} width="100%" interval="1D" isTransparent={false} showIntervalTabs={true}
  //       displayMode="single"
  //       locale="in"
  //       />
  //     </TabPanel>
  //   ))
  //   break
  // }
  case 2: {
    element = topcompanies.map((company,i) => (
      <TabPanel value={value} index={i}>
        <CompanyProfile symbol={symbols[company]} colorTheme="light" height={400} width="100%" />
      </TabPanel>
    ))
    break
  }
}

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} 
            indicatorColor="darkblue"
            textColor="primary"  
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto">
        {/* <Tab label="Global" {...a11yProps(0)} /> */}
        {topcompanies.map((company,i) => (
          <Tab label={company} {...a11yProps(i+1)} />
        ))}
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <ImpactGraph graph={graph.global}/>
      </TabPanel> */}
      {element}
    </div>
  );
}
